import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";

import { FaBars, FaTiktok, FaFacebook } from "react-icons/fa6";

const Home = () => {
  const [menu, setMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const handleClick = () => {
      setMenu(!menu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuElement = document.getElementById('menu');
      if (menuElement && !menuElement.contains(event.target)) {
        setMenu(false);
      }
    };
      const handleScroll = () => {
        setMenu(!menu);

      }
    if (menu) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);

    };
  }, [menu]);

  return (
    <div className="" id="home">
      <div className='bg-[url("./assets/background.jpg")] bg-cover bg-no-repeat bg-center h-[60vh] md:h-[100vh] flex flex-col gap-56 md:gap-96'>
        <div className="menu flex px-4 py-2 bg-red items-center justify-between">
          <div className="logo text-blue-500 text-[20px] font-bold border-b-2 px-4 rounded-[20px] border-solid border-blue-500">
            Fo Kim<span className="text-blue-100">Care</span>{" "}
          </div>
          <ul className="gap-3 hidden md:flex">
            <li className="text-black text-[20px]">
              <a href="#home">Home</a>
            </li>
            <li className="text-black text-[20px]">
              <a href="#about">About</a>
            </li>
            <li className="text-black text-[20px]">
              <a href="#donations">Donations</a>
            </li>

            <li className="text-white text-[20px]">
              <a href="#contact">Contact</a>
            </li>
          </ul>
          {isMobile && (
            <FaBars
              onClick={handleClick}
              className="text-white block md:hidden  text-[30px]"
            />
          )}
        </div>
      </div>
      {menu && (
        <div id="menu" className="bg-red flex absolute top-0 w-full flex-col gap-4 bg-blue-700 p-3">
          <div className="flex justify-between p-3">
            <h2 className="text-blue-950 text-[20px] font-bold">Fo Kim Care</h2>
            <MdClose
              size={"30px"}
              color="white"
              onClick={() => setMenu(!menu)}
            />
          </div>

          <ul className="list-none flex flex-col text-center h-[200px] gap-4">
            <li onClick={() => setMenu(false)} className="text-white text-[20px]">
              <a href="#home">Home</a>
            </li>
            <li onClick={() => setMenu(false)} className="text-white text-[20px]">
              <a href="#about">About</a>
            </li>
            <li onClick={() => setMenu(false)} className="text-white text-[20px]">
              <a href="#donations">Donations</a>
            </li>

            <li onClick={() => setMenu(false)} className="text-white text-[20px]">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      )}

      <div className="mission p-8 bg-blue-500 text-blue-950">
        <h1 className="font-bold text-[30px] my-5">Mission Statement</h1>
        <p>
          The mission of FO KIM CARE and HOMELESS SERVICES is to bridge the gap
          between crisis and stability, offering a continuum of care that
          encompasses emergency assistance, supportive services, and housing
          solutions. We aim to inspire positive transformation, promote
          community engagement, and advocate for the rights and dignity of all
          individuals experiencing homelessness.
        </p>
      </div>
      <div className="mission p-8 bg-blue-400 text-blue-950">
        <h1 className="font-bold text-[30px] my-5">Zelle</h1>
        <p>347-772-6099</p>
      </div>
      <div className="p-8 bg-blue-300 text-blue-950">
        <h1 className="font-bold text-[30px] my-5">Address</h1>
        <p>
          99-33 212 Street 
          Queens Village. N. Y. 11429
        </p>
      </div>
      <div className="p-8 bg-blue-200 text-blue-950">
        <h1 className="font-bold text-[30px] my-5">Contact Info</h1>
        <p>
           Email: fokim247@gmail.com
        </p>
      </div>

      <div className="socials flex justify-center gap-4 items-center my-10">
             <a href="https://www.facebook.com/fokim24/">
                <FaFacebook size={30}/>
             </a>
             <a href="https://www.tiktok.com/@kimbravo628?_t=ZM-8tIqavd01Re&_r=1">
                <FaTiktok size={30}/>
             </a>
      </div>

      {/* Footer */}
      <div className="footer text-black p-3 flex flex-col gap-10 py-10 bg-blue-100">
        <p className="text-center">
          &copy; copyright 2024 
        </p>
      </div>
    </div>
  );
};

export default Home;
